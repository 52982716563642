import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import ky from "ky";

const NoMore = ({ datastore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxCharacters = 16000;
  const [form, setForm] = useState({
    name: "",
    email: "",
    unitType: "",
    unitNumber: "",
    feedback: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !form.name ||
      !form.email ||
      !form.feedback ||
      !form.unitType ||
      !form.unitNumber
    ) {
      setSnackbar({
        open: true,
        message: "Please fill out required fields.",
        severity: "warning",
      });
      return;
    }

    if (form.feedback.length > maxCharacters) {
      setSnackbar({
        open: true,
        message: `Feedback must be less than ${maxCharacters} characters.`,
        severity: "warning",
      });
      return;
    }

    const payload = {
      configId: datastore.configId,
      name: form.name,
      email: form.email,
      unitType: form.unitType,
      unitNumber: form.unitNumber,
      feedback: form.feedback,
    };

    try {
      setIsSubmitting(true);

      await ky.post(window._env_.REACT_APP_API_URL + "/feedback", {
        json: payload,
      });

      setSnackbar({
        open: true,
        message: "Thank you! Your feedback has been submitted.",
        severity: "success",
      });

      setForm({
        name: "",
        email: "",
        unitType: "",
        unitNumber: "",
        feedback: "",
      });
    } catch (error) {
      console.error("Feedback submission failed", error);
      setSnackbar({
        open: true,
        message: "Something went wrong. Please try again later.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(135deg, #dbeafe 0%, #d1fae5 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMobile ? "2rem 1rem" : "4rem",
      }}
    >
      <Box
        sx={{
          maxWidth: "700px",
          width: "100%",
          textAlign: "center",
          color: "#3c3c3c",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontSize: isMobile ? "2rem" : "2.5rem",
            fontWeight: "bold",
            marginBottom: "2rem",
            textTransform: "uppercase",
          }}
        >
          Thank You for your Support!
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}
        >
          Over the past few years, Merit Badge Events became more than just a
          gathering — they were an experience filled with purpose and community.
          Scouts came together to learn, grow, and explore new interests.
          Parents and leaders stood alongside them, supporting their journey.
          Dedicated counselors generously gave their time and expertise. These
          events created lasting memories, sparked inspiration, and brought the
          spirit of Scouting to life in a powerful way.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}
        >
          Sadly, due to recent changes in the Scouting America National{" "}
          <em>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.scouting.org/resources/guide-to-advancement/"
            >
              Guide to Advancement
            </a>
          </em>{" "}
          (see section{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://filestore.scouting.org/filestore/pdf/gta-section-7.pdf"
          >
            7.0.4.10
          </a>
          ), our council and territory are no longer permitted to host these
          events under the current policy. It’s disappointing — we know how much
          these events meant to you because they meant that much to us, too.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}
        >
          But we're not giving up hope.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontSize: "1.1rem", marginBottom: "2rem" }}
        >
          We want to gather your voices — your feedback, your stories, your
          support. If our Merit Badge Events made an impact on you or your
          Scouts, we invite you to share your thoughts below. Together, we can
          present these comments to Scouting America and encourage a
          reevaluation of this decision.
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem", mt: 2 }}
        >
          <TextField
            label="Name"
            name="name"
            value={form.name}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            label="Email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
            type="email"
            fullWidth
          />
          <TextField
            label="Unit Type"
            name="unitType"
            value={form.unitType}
            onChange={handleChange}
            select
            required
            fullWidth
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="Pack">Pack</MenuItem>
            <MenuItem value="Troop">Troop</MenuItem>
            <MenuItem value="Crew">Crew</MenuItem>
            <MenuItem value="Ship">Ship</MenuItem>
            <MenuItem value="Post">Post</MenuItem>
          </TextField>
          <TextField
            label="Unit Number"
            name="unitNumber"
            value={form.unitNumber}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            label="Your Feedback"
            name="feedback"
            value={form.feedback}
            onChange={handleChange}
            multiline
            minRows={4}
            required
            fullWidth
            error={form.feedback.length > maxCharacters}
            helperText={
              <span
                style={{
                  color:
                    form.feedback.length > maxCharacters
                      ? "red"
                      : form.feedback.length > maxCharacters - 500
                        ? "#ff9800"
                        : "inherit",
                }}
              >
                {form.feedback.length}/{maxCharacters} characters
              </span>
            }
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ fontWeight: 600, paddingY: "0.75rem" }}
          >
            {isSubmitting ? "Submitting..." : "Submit Feedback"}
          </Button>
        </Box>

        {(window.location.href.includes("college") ||
          window.location.href.includes("localhost")) && (
          <Typography
            variant="body1"
            sx={{ fontSize: "1rem", marginTop: "3rem", color: "#444" }}
          >
            Wishing you all the best!
            <br />
            Yours in Scouting,
            <br />
            <strong>
              The{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://badgereg.com/"
              >
                Badge Reg
              </a>{" "}
              Team
            </strong>
          </Typography>
        )}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default NoMore;
